import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/components/layout/index.jsx";
import { MathJax } from 'better-react-mathjax';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Basic Electricity Technology`}</h1>
    <h2>{`Books`}</h2>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-Panc4bUFsR0dSeDQ"
      }}>{`Download: Instructor’s manual to accompany Chapman Electrical Machinery Fundamentals 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PZFV4WHNFWVhKRDA"
      }}>{`Download: Electrical Machinery Fundamentals By Stephan J. Chapman 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PaGxtaEZKVWdWSjg"
      }}>{`Download: Fundamentals of Electric circuits by Charles K. Alexander and Mathew N.O. Sadiku 4th Ed.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PN0ttQi1KNFVXek0"
      }}>{`Download: Renewable and efficient electric power systems by Gilbert M. Masters`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://drive.google.com/open?id=0B5kjI2-xym-PYzhndTBzTGRveVE"
      }}>{`Download: Electric circuits by Mahmood Nahvi and Joseph A. Edminister 4th Ed.`}</a></p>
    <h2>{`Lectures`}</h2>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/WORK-ENERGY-POWER-2.pdf"
      }}>{`WORK ENERGY POWER 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/WORK-ENERGY-POWER-1.pdf"
      }}>{`WORK ENERGY POWER 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Voltage-and-Current.pdf"
      }}>{`Voltage and Current`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transformers-presentation-3.pdf"
      }}>{`Transformers presentation 3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transformers-presentation-1.pdf"
      }}>{`Transformers presentation 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transformers-5.pdf"
      }}>{`Transformers 5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transformers-4.pdf"
      }}>{`Transformers 4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Transformer-presentation-2.pdf"
      }}>{`Transformer presentation 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Ohm_s-Law-KIchoff_s-Laws-etc..pdf"
      }}>{`Ohm_s Law KIchoff_s Laws etc.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/NodalAnalysis-by-DR-yaz-z-li.pdf"
      }}>{`NodalAnalysis by DR yaz z li`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/NODAL-AND-LOOP-ANALYSIS.pdf"
      }}>{`NODAL AND LOOP ANALYSIS`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Nodal-and-Loop-Analysis-Techniques.pdf"
      }}>{`Nodal and Loop Analysis Techniques`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Nodal-Analysis.pdf"
      }}>{`Nodal Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/nodal-analysis..pdf"
      }}>{`nodal analysis.`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/nodal-analysis...pdf"
      }}>{`nodal analysis..`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/network-analysis-2.pdf"
      }}>{`network analysis 2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/network-analysis-3.pdf"
      }}>{`network analysis 3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/network-analysis-4.pdf"
      }}>{`network analysis 4`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/network-analysis-5.pdf"
      }}>{`network analysis 5`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/networks.pdf"
      }}>{`networks`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Heating-Effect-of-a-Current.pdf"
      }}>{`Heating Effect of a Current)`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Introduction-to-Generator-and-Motors.pdf"
      }}>{`Introduction to Generator and Motors`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Loop-Analysis-of-resistive-circuit-in-the-context-of-dc-voltages-and-currents.pdf"
      }}>{`Loop Analysis of resistive circuit in the context of dc voltages and currents`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Loop-Analysis.pdf"
      }}>{`Loop Analysis`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Network-Analysis-1.pdf"
      }}>{`Network Analysis 1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/Basic-Electrical_Theory.pdf"
      }}>{`Basic Electrical_Theory`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/D.C-Generator-3.pdf"
      }}>{`D.C Generator 3`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/D.C-Motor1.pdf"
      }}>{`D.C Motor1`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/D.C-Motor2.pdf"
      }}>{`D.C Motor2`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://seismicconsolidation.com/wp-content/uploads/2020/03/DC-Generator2.pdf"
      }}>{`DC Generator2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      